 .daterangepicker {
       border: none;
       @include border_radius(4px);
       background-color: $gray-1300;
       padding: 0;
       margin: 10px 0 0 0;

       &::before {
             display: none;
       }

       &::after {
             display: none;
       }
 }

 .daterangepicker_input {
       display: none;
 }

 .daterangepicker {
       td {
             color: $russian-black-1200;
             @include Poppins(400);
             font-size: $font12;

             &.start-date {
                   @include border_radius (50%);
                   display: flex;
                   justify-content: center;
                   align-items: center;

                   &.end-date {
                         @include border_radius (50%);
                         display: flex;
                         align-items: center;
                         justify-content: center;
                   }
             }

             &.active {
                   background-color: $blue-color  !important;
                   border-color: transparent;
                   color: $gray-1400;
                   border-radius: 50% !important;

                   &:hover {
                         background-color: $blue-color  !important;
                         border-color: transparent;
                         color: $gray-1400;
                         border-radius: 50% !important;
                   }
             }
             &.in-range {
                  background-color: $gray-1200;
                  @include border_radius (0);
             }

       }

       th {
             color: $russian-black-1200;
             @include Poppins(600);
             font-size: $font14;
       }

       .calendar {
             td {
                   min-width: 36px;
                   height: 36px;
                   @include border_radius (50%);
                   vertical-align: middle;
             }

             th {
                   min-width: 36px;
                   height: 36px;
                   @include border_radius (50%);
                   vertical-align: middle;
             }
       }
 }



 

 .next {
       &.available {
             background: url(../../images/right-arrow2.svg) no-repeat;
             background-position: center center;
             background-size: 8px;
       }
 }

 .prev {
       &.available {
             background: url(../../images/left-arrow2.svg) no-repeat;
             background-position: center center;
             background-size: 8px;
       }
 }
 .dark-mode {
       background-color: $dark;

       header {
             background-color: $russian-black;

             .light-logo {
                   display: none;
             }

             .dark-logo {
                   display: block !important;
             }

             .navbar-nav {
                   .nav-item {
                         .nav-link {
                               &:hover {
                                     color: $gray-1400;

                                     svg {
                                           path {
                                                 fill: $gray-1400;
                                           }
                                     }
                               }
                         }
                   }
             }
       }

       .currency-btn {
             .currency-dropdown {
                   color: $gray-1400;

                   svg {
                         path {
                               fill: $gray-1400;
                         }
                   }
             }
       }

       .wallet-btn {
             border-color: $gray-1100;
             color: $gray-1400;

             &:hover {
                   background-color: $gray-1400;
                   border-color: $gray-1400;
                   color: $russian-black-1200;
             }
       }

       .lightning-btn,
       .notification-btn,
       .mood-btn {
             &:hover {
                   svg {
                         path {
                               fill: $gray-1400;
                         }
                   }
             }
       }

       .exchange,
       .bitcloud-order,
       .futures-limit,
       .bit-order-left,
       .bit-order-right,
       .bit-table-mobile .bit-table,
       .markrt-trade,
       .navbar-collapse,
       .limit-btns-mobile,
       .buy-modal,
       .currencies-pair,
       .history-inner {
             background-color: $russian-black;
       }

       .exchange-title {
             h3 {
                   color: $gray-1400;
             }
       }

       .exchange-box {
             h4 {
                   color: $gray-1400;
             }
       }

       .bitcloud-order {
             .order-price-bottom {
                   ul {
                         li {
                               color: $gray-1400;
                         }
                   }
             }
       }

       .price-increase {
             ul {
                   li {
                         color: $gray-1400;

                         &.green-color {
                               color: $primary-color;
                         }
                   }
             }
       }

       .nav-pills {
             .nav-item {
                   .nav-link {
                         color: $gray-1400;

                         &.active {
                               background-color: $gray-1500;
                               color: $gray-1400;
                         }
                   }
             }
       }

       .limit-media-left {
             .nav-pills {
                   .nav-item {
                         .nav-link {
                               color: $gray-1100;

                               &.active {
                                     background-color: $primary-color;
                                     color: $gray-1400;
                               }
                         }
                   }
             }
       }

       .form-check {
             &.form-switch {
                   .form-check-input {
                         border-color: $gray-1500;
                         background-color: $russian-black-1200;

                         &:checked {
                               background-color: $primary-color;
                         }
                   }
             }
       }

       .futures-limit {
             .limit-content {
                   form {
                         .form-group {
                               ul {
                                     li {
                                           span {
                                                 color: $gray-1200;
                                           }
                                     }
                               }

                               .form-control {
                                     background-color: transparent;
                                     border-color: $gray-1100;
                                     color: $gray-1100;

                                     &::placeholder {
                                           color: $gray-1100;
                                     }
                               }
                         }
                   }

                   .bid-slider {
                         ul {
                               li {
                                     color: rgba($gray-1200, 0.5);
                               }
                         }
                   }

                   .limit-price {
                         ul {
                               li {
                                     span {
                                           color: $gray-1200;
                                     }
                               }
                         }
                   }

                   .limit-list {
                         ul {
                               li {
                                     a {
                                    
                                          //#E6E8EC
                                          background-color: #353945;
                                           &.active {
                                                 background-color: $gray-1200;
                                                 color: $russian-black-1200;
                                           }
                                           &:hover {
                                                background-color: $gray-1400;
                                                color: $gray-1500;
                                          }
                                     }
                               }
                         }
                   }
             }
       }

       .slider-container {
             .slider {
                   background-color: $gray-1100;
             }

       }

       .bit-table {
             .table-responsive {
                   table {
                         tbody {
                               tr {
                                     td {
                                           color: $gray-1200;
                                     }
                               }
                         }
                   }
             }
       }

       .deposit-btn {
             ul {
                   li {
                         a {
                               background-color: $gray-1200;
                               color: $russian-black-1200;

                               &:hover {
                                     svg {
                                           path {
                                                 fill: $russian-black-1200;
                                           }
                                     }
                               }

                               &.active {
                                     background-color: $blue-color;
                                     color: $white;

                                     &:hover {
                                           svg {
                                                 path {
                                                       fill: $white;
                                                 }
                                           }
                                     }
                               }
                         }
                   }
             }
       }

       .bit-order-right {
             .deposit-inner {
                   ul {
                         li {
                               span {
                                     color: $gray-1200;
                               }
                         }
                   }
             }
       }

       footer {
             background-color: $russian-black;

             .footer-left,
             .copyright {
                   border-color: $russian-black-1200;
             }

             .footer-contect,
             .footer-links {
                   border-color: $russian-black-1200;

                   h4 {
                         color: $gray-1700;
                   }

                   ul {
                         li {
                               color: $gray-1100;
                         }

                         a {
                               color: $gray-1100;
                         }
                   }
             }

             .footer-newsletter {
                   h4 {
                         color: $gray-1700;
                   }

                   p {
                         color: $gray-1200;
                   }

                   form {
                         .form-control {
                               border-color: $russian-black-1200;
                               background-color: transparent;
                               color: $gray-1100;

                               &::placeholder {
                                     color: $gray-1100;
                               }
                         }
                   }
             }
       }

       .buy-modal {
             .media {
                   h4 {
                         color: $gray-1200;
                   }

                   span {
                         color: $gray-1200;

                         img {
                               filter: brightness(370.5);
                         }
                   }
             }
       }

       .markrt-trade {
             .form-control {
                   border-color: $russian-black-1200;
                   background-color: transparent;
                   color: $gray-1100;
             }
       }

       .bitcloud-order {
             .order-book {
                   ul {
                         li {
                               a {
                                     &.active {
                                           background-color: $gray-1500;
                                     }
                               }
                         }
                   }

                   a {
                         &.drop-list {
                               background-color: $gray-1500;
                               color: $gray-1200;
                         }
                   }
             }
       }

       .order-book-right {
             .relative {
                   .form-control {
                         border-color: $gray-1500;
                         background-color: $gray-1500;
                         color: $gray-1100;
                   }
             }
       }

       .currencies-pair {
             table {
                   tbody {
                         tr {
                               td {
                                     color: $gray-1200;
                               }
                         }
                   }
             }
       }

       .currencies-pair {
             form {
                   .form-control {
                         border-color: $gray-1500;
                         background-color: transparent;
                         color: $gray-1100;

                         &::placeholder {
                               color: $gray-1100;
                         }
                   }
             }
       }

       .modal-content {
             background-color: $russian-black-1200;
       }
       .modify-modal,
       .leverage-modal{
            background-color: $russian-black-1200;
       }

       .modal-body {
             .title {
                   h3 {
                         color: $gray-1200;
                   }

                   a {
                         svg {
                               path {
                                     fill: $gray-1200;
                               }
                         }
                   }
             }
       }

       .modify-tp {
             .media {
                   span {
                         color: $gray-1200;
                   }
             }
       }

       .modify-box {
             background-color: $gray-1500;

             ul {
                   li {
                         span {
                               color: $gray-1200;
                         }
                   }
             }
       }

       .modify-form {
             form {
                   .form-group {
                         span {
                               color: $gray-1200;
                         }
                         .form-control {
                              color: white;
                        }
                   }
             }
       }

       .error-message {
             background-color: $gray-1500;

             a {
                   svg {
                         path {
                               fill: $gray-1200;
                         }
                   }
             }
       }

       .modal-backdrop {
             background-color: rgba(0, 0, 0, 0.8);
       }

       .inquiry-inner {
             h3 {
                   color: $gray-1400;
             }

             form {
                   .form-group {
                         .form-control {
                               background-color: $russian-black-1200;
                               border-color: $gray-1500;
                         }
                   }
             }
       }

       .tabs-second {
             border-color: $gray-1100;

             .nav-pills {
                   .nav-item {
                         .nav-link {
                               &.active {
                                     background-color: $blue-color;
                                     color: $white;
                               }
                         }
                   }
             }
       }

       .history-spot {
             .media {
                   .date-range {
                         .form-group {
                               .form-control {
                                     background-color: transparent;
                                     border-color: $gray-1100;
                                     color: $gray-1200;

                                     &::placeholder {
                                           color: $gray-1200;
                                     }
                               }
                         }
                   }
             }
       }

       .swap-inner {
             background-color: $russian-black-1200;

             h3 {
                   color: $gray-1400;
             }
       }

       .swap-media {
             border-color: $gray-1500;

             .form-control {
                   border-color: $gray-1100;
                   color: $gray-1200;

                   &::placeholder {
                         color: $gray-1200;
                   }
             }
       }

       .swap-right,
       .swap-summary {
             h4 {
                   color: $gray-1200;
             }
       }

       .swap-summary-box {
             background-color: $gray-1500;
             border-color: $gray-1100;

             ul {
                   li {
                         span {
                               color: $gray-1200;
                         }
                   }
             }
       }

       .swap-dark {
             display: block;
       }

       .swap-light {
             display: none;
       }

       .alert-box {
             background-color: $russian-black-1200;

             p {
                   color: $gray-1400;
             }

             h3 {
                   color: $gray-1400;
             }
       }

       .cancel-btn {
             border-color: $gray-1100;
             color: $gray-1400;

             &:hover {
                   background-color: $gray-1100;
                   border-color: $gray-1100;
                   color: $russian-black;
             }
       }

       .option-table {
             background-color: $russian-black-1200;
       }

       .balance-media {
             span {
                   color: $gray-1400;
             }
       }

       .option-box {
             background-color: $gray-1500;

             h4 {
                   color: $gray-1200;
             }
       }

       .quantity-box {
             .count {
                   color: $gray-1200;
                   border-color: $gray-1200;
             }
       }

       .leverage-box {
             p {
                   color: $gray-1200;
             } 
       }
 }
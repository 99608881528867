 .inquiry-sec{
      padding: 136px 0;
 }
 .inquiry-inner {
       h3 {
             color: $russian-black-1200;
             @include DMSans(700);
             font-size: $font64;
             letter-spacing: -1.28px;
             line-height: 1;
             text-align: center;
       }

       form {
            max-width: 546px;
            width: 100%;
            margin: 85px auto 0;
             .form-group {
                  margin-bottom: 40px;
                  &:last-child{
                        margin: 0;
                  }
                   label {
                         color: $gray-1700;
                         @include Poppins(700);
                         font-size: $font12;
                         line-height: 1;
                         text-transform: uppercase;
                         display: block;
                         margin-bottom: 13px;
                   }

                   .form-control {
                         background-color: $gray-1400;
                         border: 1px solid $gray-1200;
                         height: 48px;
                         box-shadow: none;
                         @include border_radius(12px);
                         color: $gray-1100;
                         @include Poppins(500);
                         padding-left: 16px;
                         font-size: $font14;
                         &::placeholder {
                               color: $gray-1100;
                         }
                   }
                   textarea{
                        &.form-control{
                              height: 156px;
                              padding-top: 12px;
                        }
                   }
                   .btn-default{
                        border: none;
                        background-color: $blue-color;
                        border: 2px solid $blue-color;
                        height: 40px;
                        width: 64px;
                        margin-left: auto;
                        font-size: $font14;
                        text-transform: capitalize;
                        @include transition(0.5s);
                        &:hover{
                              background-color: transparent;
                              color: $blue-color;
                              @include transition(0.5s);
                        }
                   }
             }
       }
 }
$gray-1100: #777E90;
$gray-1200: #E6E8EC;
$gray-1400: #FCFCFD;
$gray-1600: #F4F5F6;
$gray-1700: #B1B5C3;
$russian-black-1200: #23262F;
$blue-color: #3772FF;
$font24: 24px;
$font16: 16px;
$font12: 12px;
$font11: 11.75px;
$font10: 10px;


@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
      font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
      font-family: "DM Sans", sans-serif;
      font-weight: $font-weight;
}

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}

@mixin translate ($x, $y) {
      @include transform(translate($x, $y));
}

@mixin transform($transforms) {
      -moz-transform: $transforms;
      -o-transform: $transforms;
      -ms-transform: $transforms;
      -webkit-transform: $transforms;
      transform: $transforms;
}

ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}

a {
      text-decoration: none;
}

.swap-default {
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font16;
      height: 48px;
      background-color: $blue-color;
      display: flex;
      align-items: center;
      @include border_radius (90px);
      justify-content: center;
      margin-top: 30px;
      border: 2px solid transparent;

      &:hover {
            background-color: transparent;
            border-color: $blue-color;
            color: $blue-color;
      }
}

.swap-sec {
      padding: 40px 0;
}

.swap-box {
      max-width: 573px;
      width: 100%;
      margin: 0 auto;
      padding: 40px 46px;
      background-color: $gray-1400;

      h3 {
            color: $russian-black-1200;
            @include Poppins(600);
            font-size: $font24;
            text-align: center;
            margin-bottom: 36px;
            margin-top: 0;
      }
}

.swap-left {
      max-width: 128px;
      width: 100%;

      .form-control {
            padding-left: 42px;
            padding-right: 36px;
      }

}

.relative {
      position: relative;
}

.swap-media {
      display: flex;
      gap: 22px;
      padding-bottom: 30px;
      border-bottom: 2px solid $gray-1200;

      label {
            color: $gray-1700;
            @include Poppins(700);
            font-size: $font10;
            text-transform: uppercase;
            display: block;
            margin-bottom: 5px;
            line-height: 1;
      }

      .form-control {
            border: 1px solid $gray-1200;
            height: 39.74px;
            background-color: transparent;
            @include border_radius(9.87px);
            color: $russian-black-1200;
            @include Poppins(500);
            font-size: $font11;
            box-shadow: none;
      }
}

.arrow-box {
      position: absolute;
      top: 50%;
      @include translate (0, -50%);
      right: 16px;
      display: flex;
      align-items: center;
      @include Poppins(500);
      font-size: $font12;
      color: $blue-color;
}

.btn-box {
      position: absolute;
      top: 50%;
      @include translate (0, -50%);
      left: 10px;
      display: flex;
      align-items: center;
}

.swap-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      height: 32px;

      a {
            display: flex;
            align-items: center;
            justify-content: center;
      }
}

.swap-right {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .form-control {
            border: 2px solid $gray-1200;
            height: 41.25px;
            width: 100%;
            padding-left: 20px;
      }

      h4 {
            @include Poppins(600);
            font-size: $font12;
            color: $russian-black-1200;
            line-height: 1;
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: end;

            span {
                  margin-left: 10px;
                  color: $gray-1100;
                  display: inline-flex;
            }
      }
}

.swap-summary {
      margin: 34px 0 0 0;

      h4 {
            color: $russian-black-1200;
            @include Poppins(500);
            font-size: $font10;
            margin-bottom: 8px;
      }
}

.swap-summary-box {
      background-color: $gray-1600;
      border: 1px solid $gray-1200;
      padding: 8px 20px;
      @include border_radius (4px);

      ul {
            li {
                  color: $gray-1100;
                  @include Poppins(500);
                  font-size: $font12;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 2px 0;
                  margin: 0 0 15px 0;

                  &:last-child {
                        margin: 0;
                  }

                  span {
                        color: $russian-black-1200;
                        @include Poppins(500);
                        font-size: $font12;
                        line-height: 1;
                        display: inline-block;
                  }
            }
      }
}

.swap-dark {
      display: none;
}
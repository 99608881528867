@import ../../../styles/helpers

.currency
    padding: 16px
    border-radius: 4px
    background: $n8
    +dark
        background: #17181B

.nav
    display: flex
    margin-bottom: 12px

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.form
    position: relative
    margin-bottom: 12px

.input
    width: 100%
    height: 40px
    padding: 0 40px 0 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.table
    width: 100%

.row
    display: flex
    .col
        +caption-2
        font-weight: 500
        cursor: pointer
    &:hover
        background: $n6
        +dark
            background: $n3

.col
    width: 25%
    display: table-cell
    padding: 4px
    &:first-child
        padding-left: 0
        width: 30%
    &:last-child
        width: 45%
        padding-right: 0
        text-align: right

.line
    display: inline-flex
    align-items: center

.favorite
    position: relative
    top: -2px
    margin-right: 4px

.info
    span
        color: $n4

.positive
    color: $p4

.negative
    color: $p3

$gray-1400: #FCFCFD;
$primary-color: #58BD7D;
$gray-1700: #B1B5C3;
$gray-1200: #E6E8EC;
$gray-1100: #777E90;
$gray-1600: #F4F5F6;  
$white: #ffffff;
$russian-black-1200: #23262F;
$russian-black: #141416;
$gray-1500: #353945;
$blue-color: #3772FF; 
$secondary-color: #FF6838; 
$font12: 12px;
$font13: 13.57px;
$font14: 14px; 
$font16: 16px; 
$font10: 10px;

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}
@mixin transition($time) {
      -webkit-transition: all $time ease-out 0s;
      -moz-transition: all $time ease-out 0s;
      -ms-transition: all $time ease-out 0s;
      -o-transition: all $time ease-out 0s;
      transition: all $time ease-out 0s;
  }
@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
      font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
      font-family: "DM Sans", sans-serif;
      font-weight: $font-weight;
}


.btn-default {
      @include border_radius (90px);
      height: 48px;
      width: 100%;
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font16;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(0.5s);
      border: 2px solid transparent;
      &:hover{
           background-color: transparent;
           color: $blue-color;
           @include transition(0.5s);
     }
}

.green-bg {
      background-color: $primary-color;
      @include transition(0.5s);
      &:hover{
           background-color: transparent;
           border-color: $primary-color;
           color: $primary-color;
           @include transition(0.5s);
     }
}
.orange-bg{
     background-color: $secondary-color;
     &:hover{
           background-color: transparent;
           border-color: $secondary-color;
           color: $secondary-color;
           @include transition(0.5s);
     }
}

ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}
a{
      text-decoration: none;
}

.nav-pills {
      gap: 8px;
      margin-bottom: 22px;

      .nav-item {
            .nav-link {
                  padding: 0 13px;
                  color: $gray-1100;
                  font-size: $font14;
                  @include DMSans(700);
                  height: 28px;
                  border: none;
                  @include border_radius(100px);

                  &.active {
                        background-color: $gray-1200;
                        color: $russian-black-1200;
                  }
            }
      }
}

.limit-media-left {
      .nav-pills {
            border: 1px solid $gray-1100;
            @include border_radius (100px);
            max-width: 268px;
            width: 100%;
            margin: 0 auto 16px;
            gap: 0;

            .nav-item {
                  width: 50%;

                  .nav-link {
                        width: 100%;
                        color: $gray-1100;
                        font-size: $font13;
                        height: 42px;

                        &.active {
                              background-color: $primary-color;
                              color: $white;
                        }
                  }
            }
      }
}

.futures-limit {
      background-color: transparent !important; 
      @include border_radius (4px);
      padding: 16px;
      flex: 1;
      width: 100%;
      max-width: 361px;
      width: 100%;
      margin: 0 auto;

      .limit-content {
            .media {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 24px;

                  span {
                        color: $gray-1400;
                        @include Poppins(600);
                        font-size: $font12;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        height: 24px;
                        @include border_radius(24px);
                        background-color: $primary-color;
                  }
            }

            form {
                  .form-group {
                        margin-bottom: 22px;

                        .label-text {
                              color: $gray-1700;
                              @include Poppins(700);
                              font-size: $font10;
                              display: block;
                              line-height: 1;
                              text-transform: uppercase;
                              margin-bottom: 6px;
                        }

                        .relative {
                              position: relative;
                        }

                        .form-control {
                              border: 1.72px solid $gray-1200;
                              @include border_radius(10.31px);
                              height: 41.25px;
                              background-color: $gray-1400;
                              padding-left: 14px;
                              color: $russian-black-1200;
                              @include Poppins(400);
                              font-size: $font12;
                              box-shadow: none;
                              padding-right: 115px;

                              &::placeholder {
                                    color: $gray-1100;
                              }
                        }

                        ul {
                              display: flex;
                              align-items: center;
                              gap: 10px;
                              position: absolute;
                              top: 50%;
                              transform: translate(0, -50%);
                              right: 14px;

                              li {
                                    color: $gray-1100;
                                    @include Poppins(600);
                                    font-size: $font12;

                                    span {
                                          color: $russian-black-1200;
                                    }
                              }
                        }
                  }
            }

            .bid-slider {
                  ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 13px 0 0 0;

                        li {
                              color: rgba($russian-black, 0.5);
                              @include Poppins(500);
                              font-size: $font12;
                        }
                  }

                  ol {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 14px;
                        left: 0;
                        width: 100%;
                        justify-content: space-between;
                        padding: 0px 10px;

                        li {
                              background-color: $gray-1200;
                              width: 1.51px;
                              height: 4.54px;
                              display: flex;
                              align-items: center;
                              @include border-radius(2px)
                        }
                  }
            }

            .limit-price {
                  margin: 24px 0;

                  ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        li {
                              color: $gray-1100;
                              @include Poppins(600);
                              font-size: $font12;
                              display: flex;
                              align-items: center;
                              gap: 10px;

                              span {
                                    color: $russian-black-1200;
                                    display: inline-block;
                              }
                        }
                  }
            }

            .limit-list {
                  margin-bottom: 20px;

                  ul {
                        display: grid;
                        grid-template-columns: 4fr 4fr 4fr;
                        gap: 12px;

                        li {
                              width: 100%;

                              a {
                                    color: $gray-1100;
                                    @include DMSans(700);
                                    font-size: $font12;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include border_radius (100px);
                                    background-color: #E6E8EC;
                                    height: 28px;

                                    &.active {
                                          background-color: $gray-1500;
                                          color: $gray-1400;
                                    }

                                    &:hover {
                                          background-color: $gray-1500;
                                          color: $gray-1400;
                                    }
                              }
                        }
                  }
            }
      }
}

.limit-btns {
      ul {
            gap: 12px;
            width: 100%;
            display: grid;
            grid-template-columns: 6fr 6fr;
      }
}


.form-check {
      &.form-switch {
            padding-left: 0;
            min-height: auto;
            margin: 0;
            display: flex;
            align-items: center;
            .form-check-input {
                  border: 1px solid $gray-1200;
                  background-color: $gray-1600;
                  @include border_radius(20px);
                  width: 40px;
                  height: 20px;
                  --bs-form-switch-bg: url(../../images/uncheck-icon.svg);
                  background-position: 1px center;
                  background-size: 16px;
                  margin: 0;

                  &:focus {
                        box-shadow: none;
                  }

                  &:checked {
                        background-color: $primary-color;
                        border-color: $primary-color;
                        box-shadow: none;
                        background-size: 21px;
                        background-position: right center;
                        --bs-form-switch-bg: url(../../images/checked-icon.svg);
                  }
            }

            .form-check-label {
                  color: $gray-1100;
                  @include Poppins(500);
                  font-size: $font12;
                  line-height: 1;
                  margin-left: 8px;
            }
      }
}
 .history-sec {
       padding: 60px 0 88px;
 }

 .history-inner {
       @include border_radius(4px);
       background-color: $white;
       padding: 50px 40px;
 }

 .tabs-second {
       text-align: center;
       border-bottom: 1px solid $gray-1200;
       padding-bottom: 30px;
       margin-bottom: 30px;

       .nav-pills {
             border: 1px solid $gray-1100;
             background-color: transparent;
             @include border_radius (25.44px);
             display: inline-flex;
             margin: 0;

             .nav-item {
                   .nav-link {
                         color: $gray-1100;
                         font-size: $font13;
                         height: 41.14px;
                         padding: 0 52px;

                         &.active {
                               background-color: $blue-color;
                               color: $white;
                         }
                   }
             }
       }
 }

 .history-spot {
       .media {
             display: flex;
             align-items: center;
             justify-content: space-between;

             .date-range {
                   display: flex;
                   align-items: center;
                   gap: 16px;

                   &.date-range-future {
                         .form-group {
                               .form-control {
                                     width: 106px;
                                     @include border_radius(90px);
                               }
                         }
                   }

                   .form-group {
                         position: relative;

                         .form-control {
                               color: $gray-1100;
                               @include Poppins(400);
                               font-size: $font12;
                               border: 2px solid $gray-1200;
                               height: 40px;
                               padding-left: 16px;
                               width: 213px;
                               box-shadow: none;
                               padding-right: 40px;
                               @include border_radius(8px);
                         }

                         span {
                               position: absolute;
                               right: 14px;
                               top: 50%;
                               @include translate (0, -50%);
                         }
                   }
             }

             .nav-pills {
                   .nav-item {
                         .nav-link {
                               &.active {
                                     background-color: $gray-1500;
                                     color: $gray-1400;
                               }
                         }
                   }
             }
       }

       .bit-table {
             .table-responsive {
                   -ms-overflow-style: none;
                   /* Internet Explorer 10+ */
                   scrollbar-width: none;

                   /* Firefox */
                   &::-webkit-scrollbar {
                         display: none;
                   }

                   table {
                         width: 1450px;
                         border-spacing: 0px 24px;
                         cursor: grab;

                         thead {
                               tr {
                                     th {
                                           padding: 15px 10px;
                                           line-height: 20px;
                                     }
                               }
                         }

                         tbody {
                               tr {
                                     td {
                                           padding: 0px 10px;
                                           line-height: 20px;
                                     }
                               }
                         }
                   }

             }
       }
 }

 /**** TRADING OPTION ******/
 .trading-option-sec {
       padding: 0 4px 4px;
       display: flex;
       align-items: center;
       justify-content: center;

       .image-holder {
             display: flex;
             align-items: center;

             img {
                   width: 100%;
             }
       }

 }
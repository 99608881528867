@import ../../../../../styles/helpers

.head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px

.title
    +body-bold-1

.counter
    +caption-bold-2
    svg
        margin-right: 4px

.field
    display: flex
    align-items: center
    margin-bottom: 8px
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 12px

.input
    width: 100%
    flex-grow: 1
    height: 48px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 12px
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.label,
.currency
    font-weight: 500
    font-size: 12px
    color: $n4
    pointer-events: none

.button
    width: 100%

.link
    padding: 6px 12px
    +button-2
    transition: all .2s
    color: $n2
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 14px
    +dark
        color: $n8
    &:hover
        color: $n3
        +dark
            color: $n6

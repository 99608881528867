// common styles
body
    min-width: 375px
    background: $n8
    +poppins
    font-size: 14px
    line-height: (24/14)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $n2
    +dark-body
        background: $n1
        color: $n8

a
    text-decoration: none

svg,
img
    vertical-align: middle

.desktop
    &-hide
        +d
            display: none !important
    &-show
        display: none !important
        +d
            display: block !important
    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important
    &-show
        display: none !important
        +t
            display: block !important

.mobile
    &-hide
        +m
            display: none !important
    &-show
        display: none !important
        +m
            display: block !important

.mdl_overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000
    backdrop-filter: blur(8px)

.mdl_content
    padding: 20px
    border-radius: 16px
    position: relative
    border: 2px solid $n2
    background: $n8
    +dark-common
        background: $n2
        border: 1px solid $n8

.mdl_close
    position: absolute
    top: 18px
    right: 10px
    width: 40px // Customize the size as needed
    mask: url('../assets/images/close-icon.svg') no-repeat center center
    background-color: $n2
    mask-size: contain
    height: 40px
    font-size: 1.2rem
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    padding: 0
    line-height: 1 // Ensures the text is centered vertically
    +dark-common
        background: #FCFCFD

@keyframes slideInFromBottom
    from
        transform: translateY(100%) // Start below the viewport
        opacity: 0
    to
        transform: translateY(0) // End at the final position
        opacity: 1

@keyframes slideOutToBottom
    from
        transform: translateY(0) // Start at the final position
        opacity: 1
    to
        transform: translateY(100%) // Move down and out of view
        opacity: 0
@media only screen and (max-width: 1023px)
    // .mdl_overlay
    //     bottom: 0
    //     top: unset
    //     transition: all .3s
    //     transform: translateY(-100%)
    //     &.show
    //         box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1)
    // .mdl_content
    //     &.show
    //         transform: translateY(-100%)
    //         box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1)

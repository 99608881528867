$white: #ffffff;
$gray-1100: #777E90;
$gray-1200: #E6E8EC;
$gray-1300: #F1F2F4;
$gray-1400: #FCFCFD;
$russian-black-1200: #23262F;
$russian-black: #141416;
$gray-1600: #F4F5F6;
$gray-1700: #B1B5C3;
$blue-color: #3772FF;
$secondary-color: #FF6838;
$primary-color: #58BD7D;
$font24: 24px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
      font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
      font-family: "DM Sans", sans-serif;
      font-weight: $font-weight;
}

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}

ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}

a {
      text-decoration: none;
} 
@mixin translate ($x, $y) {
      @include transform(translate($x, $y));
}

@mixin transform($transforms) {
      -moz-transform: $transforms;
      -o-transform: $transforms;
      -ms-transform: $transforms;
      -webkit-transform: $transforms;
      transform: $transforms;
}

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}


@mixin box-shadow($value) {
      -webkit-box-shadow: $value;
      -moz-box-shadow: $value;
      box-shadow: $value;
}

@mixin transition($time) {
      -webkit-transition: all $time ease-out 0s;
      -moz-transition: all $time ease-out 0s;
      -ms-transition: all $time ease-out 0s;
      -o-transition: all $time ease-out 0s;
      transition: all $time ease-out 0s;
}


.alert-default {
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font14;
      height: 40px;
      background-color: $blue-color;
      border: 2px solid $blue-color;
      display: flex;
      align-items: center;
      @include border_radius (90px);
      justify-content: center;
      width: 100%;

      &:hover {
            background-color: transparent;
            color: $blue-color;
      }
}
.btn-default {
      @include border_radius (90px);
      height: 48px;
      width: 100%;
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font16;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(0.5s);
      border: 2px solid transparent;
      &:hover{
           background-color: transparent;
           color: $blue-color;
           @include transition(0.5s);
     }
}


.modal-backdrop {
      background-color: rgba($gray-1300, 0.8);
      opacity: 1 !important;
}

.modal-dialog {
      max-width: 448px;
      width: 100%;
      margin: 0 auto;

      .modal-content {
            border: none;
      }
}

.modal-body {
      padding: 0 !important;
}

.modify-modal {
      padding: 30px 32px;
      max-width: 448px;
      width: 100%;
      margin: 0 auto;
      background-color: $gray-1400;
      @include border_radius(20px);
      border: none;
      @include box-shadow(0px 64px 64px -48px rgba(31, 47, 70, 0.12));

      .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;

            h3 {
                  color: $russian-black-1200;
                  @include Poppins(600);
                  font-size: $font24;
                  line-height: 1;
            }

            a {
                  border: 2px solid $gray-1200;
                  @include border_radius (50%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
            }
      }
}

.modify-tp {
      .media {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            p {
                  color: $gray-1100;
                  @include Poppins(600);
                  font-size: $font12;
                  line-height: 1;
            }

            span {
                  display: flex;
                  align-items: center;
                  color: $russian-black;
                  @include Poppins(500);
                  font-size: $font12;
                  line-height: 1;
                  gap: 6px;
            }
      }
}

.modify-box {
      background-color: $gray-1600;
      @include border_radius (4px);
      padding: 20px 24px;

      ul {
            display: grid;
            grid-template-columns: 6fr 6fr;

            li {
                  border-right: 1px solid $gray-1700;
                  padding: 4px 0;

                  &:last-child {
                        border: none;
                        padding-left: 30px;
                  }

                  p {
                        color: $gray-1100;
                        @include Poppins(500);
                        font-size: $font12;
                        line-height: 1;
                        margin-bottom: 12px;
                  }

                  span {
                        color: $russian-black;
                        @include Poppins(500);
                        font-size: $font16;
                        line-height: 1;
                        display: block;
                  }
            }
      }
}

.modify-form {
      margin: 24px 0 0 0;

      form {
            .form-group {
                  margin-bottom: 20px;

                  &:last-child {
                        margin: 0;
                  }

                  label {
                        color: $gray-1700;
                        @include Poppins(700);
                        font-size: $font12;
                        line-height: 1;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        text-transform: uppercase;
                  }

                  .relative {
                        position: relative;
                  }

                  .form-control {
                        background-color: transparent;
                        border: 2px solid $gray-1200;
                        @include border_radius (12px);
                        height: 48px;
                        padding-left: 16px;
                        color: $russian-black;
                        @include Poppins(400);
                        font-size: $font14;
                        box-shadow: none;
                        padding-right: 60px;

                        &::placeholder {
                              color: $gray-1100;
                        }
                  }

                  span {
                        color: $russian-black-1200;
                        @include Poppins(400);
                        font-size: $font14;
                        display: block;
                        line-height: 1;
                        position: absolute;
                        right: 16px;
                        top: 50%;
                        @include translate (0, -50%);
                  }

                  .btn-default {
                        border: none;
                        background-color: $blue-color;
                        color: $white;
                        margin-top: 34px;
                        border: 2px solid transparent;
                        @include transition(0.5s);

                        &:hover {
                              background-color: transparent;
                              border-color: $blue-color;
                              color: $blue-color;
                              @include transition(0.5s);
                        }
                  }
            }
      }
}

.error-message {
      background-color: $gray-1600;
      @include border_radius (4px);
      display: flex;
      align-items: center;
      margin-top: 4px;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 13px;
      padding-top: 6px;
      padding-bottom: 6px;

      p {
            color: $secondary-color;
            @include Poppins(500);
            font-size: $font10;
            line-height: 16px;
            flex: 1;
            margin-left: 8px;
      }

      a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;

            svg {
                  path {
                        fill: $gray-1700;
                  }
            }
      }
}

.leverage-box {
      h4 {
            color: $gray-1100;
            @include Poppins(600);
            font-size: $font12;
            text-align: center;
            margin-bottom: 22px;
      }

      p {
            color: $russian-black-1200;
            @include Poppins(600);
            font-size: $font12;
            line-height: 20px;
            text-align: center;
            margin: 20px 0 20px;
      }

      ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            li {
                  a {
                        color: $gray-1400;
                        @include DMSans(700);
                        font-size: $font14;
                        background-color: $primary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 24px;
                        @include border_radius (90px);
                        padding: 0 16.5px;
                  }
            }
      }
}

.quantity-box {
      border: 2px solid $gray-1200;
      @include border_radius (12px);
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      button {
            border: none;
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
            justify-content: center;
      }

      .count {
            border: none;
            @include border_radius(0);
            padding: 0;
            color: $russian-black-1200;
            text-align: center;
            @include Poppins(500);
            font-size: $font16;
            flex: 1;
            background-color: transparent;
      }
}
 /******* RANGE SLIDER ******/
 .slider-container {
       position: relative;

       .slider {
             -webkit-appearance: none;
             width: 100%;
             height: 1.51px;
             background: $gray-1200;
             outline: none;
             opacity: 1;
             border-radius: 0;
       }
 }


 /* Styling the slider thumb */
 .slider::-webkit-slider-thumb {
       -webkit-appearance: none;
       appearance: none;
       width: 16px;
       height: 16px;
       background: $gray-1600;
       border: 3.028px solid $gray-1100;
       cursor: pointer;
       position: relative;
       z-index: 100;
       border-radius: 50px;
       box-shadow: 0px 3.028px 12.113px -6.057px rgba($black-1200, 0.10);
 }

 .slider::-moz-range-thumb {
       width: 16px;
       height: 16px;
       background: $gray-1600;
       border: 3.028px solid $gray-1100;
       cursor: pointer;
       border-radius: 50px;
       position: relative;
       z-index: 100;
       box-shadow: 0px 3.028px 12.113px -6.057px rgba($black-1200, 0.10);
 }

 /* Styling the value display */
 .value {
       display: none;
 }
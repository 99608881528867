footer {
      background-color: $gray-1400;
      margin-top: 4px;

      .footer-left {
            padding-top: 80px;
            padding-bottom: 64px;
            height: 100%;
            display: flex;
            gap: 161px;
            border-right: 1px solid $gray-1200;
      }

      .links-mobile {
            display: none;
      }

      .footer-links {
            h4 {
                  color: $russian-black-1200;
                  @include Poppins(700);
                  font-size: $font12;
                  text-transform: uppercase;
                  display: none;
                  align-items: center;
                  justify-content: space-between;
            }

            ul {
                  li {
                        margin-bottom: 26px;

                        &:last-child {
                              margin: 0;
                        }

                        a {
                              color: $gray-1100;
                              @include DMSans(700);
                              font-size: $font14;
                              display: block;
                              line-height: 1;

                              &:hover {
                                    color: $secondary-color;
                              }
                        }
                  }
            }
      }

      .footer-contect {
            border-right: 1px solid $gray-1200;
            padding-left: 44px;
            padding-top: 80px;
            padding-bottom: 64px;
            height: 100%;

            h4 {
                  color: $russian-black-1200;
                  @include Poppins(700);
                  font-size: $font12;
                  line-height: 1;
                  text-transform: uppercase;
                  margin-bottom: 44px;
            }

            ul {
                  li {
                        color: $russian-black-1200;
                        @include Poppins(400);
                        font-size: $font14;
                        line-height: 1;
                        margin-bottom: 16px;

                        &:last-child {
                              margin: 0;
                        }

                        a {
                              color: $russian-black-1200;

                              &:hover {
                                    color: $secondary-color;
                              }
                        }
                  }
            }
      }

      .footer-newsletter {
            max-width: 256px;
            width: 100%;
            margin-left: auto;
            padding-top: 80px;
            padding-bottom: 64px;

            h4 {
                  color: $russian-black-1200;
                  @include Poppins(700);
                  font-size: $font12;
                  line-height: 1;
                  text-transform: uppercase;
            }

            p {
                  color: $russian-black-1200;
                  @include Poppins(400);
                  font-size: $font14;
                  line-height: 24px;
                  margin: 38px 0 24px 0;
            }

            form {
                  position: relative;

                  .form-control {
                        border: 2px solid $gray-1200;
                        @include border_radius(90px);
                        height: 48px;
                        width: 100%;
                        padding-left: 16px;
                        color: $russian-black;
                        @include Poppins(400);
                        font-size: $font14;
                        box-shadow: none;
                        background-color: $gray-1400;
                        padding-right: 45px;

                        &::placeholder {
                              color: $gray-1100;
                        }
                  }

                  a {
                        @include border_radius(100px);
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $blue-color;
                        position: absolute;
                        top: 50%;
                        right: 8px;
                        @include translate(0, -50%);

                        &:hover {
                              background-color: $secondary-color;
                        }
                  }
            }
      }
}

.copyright {
      border-top: 1px solid $gray-1200;
      padding: 22px 0;

      .copyright-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                  color: $gray-1100;
                  @include Poppins(400);
                  font-size: $font12;
                  line-height: normal;
            }

            ul {
                  display: flex;
                  align-items: center;
                  gap: 24px;

                  li {
                        a {
                              display: flex;
                              align-items: center;
                        }
                  }
            }
      }
}

.footer-light-logo,
.footer-dark-logo {
      display: none;
}
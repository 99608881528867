 .buy-modal {
      position: fixed;
      left: 0;
      bottom: -100%;
      width: 100%;
      @include transition(0.5s);
      z-index: 100;
      padding: 16px 16px 32px 16px;
      background-color: $white;
      &.active{
            bottom: 0;
            @include transition(0.5s);
      }
       .media {
             display: flex;
             align-items: center;
             justify-content: space-between;
             padding: 4px 0;
             margin-bottom: 16px;

             p {
                   color: $gray-1100;
                   @include Poppins(500);
                   font-size: $font16;
                   line-height: 1;
             }

             h4 {
                   color: $russian-black-1200;
                   @include Poppins(600);
                   font-size: $font24;
                   line-height: 1;
             }

             span {
                   color: $russian-black-1200;
                   @include Poppins(600);
                   font-size: $font12;
                   line-height: 1;
                   display: flex;
                   gap: 5px;
                   align-items: center;
             }
       }

       .futures-limit {
             .limit-content {
                   form {
                         .form-group {
                               margin-bottom: 14px;
                         }

                         .bid-slider {
                               margin-bottom: 16px;
                         }
                   }
             }
       }

       .limit-btns {
            margin-top: 48px;
             .btn-default {
                   background-color: $gray-1700;
                   &.active{
                        background-color: $russian-black-1200;
                        color: $gray-1400;
                   }
                   &:hover{
                        background-color: $russian-black-1200;
                        color: $gray-1400;
                   }
             }
       }
 }
 #sell-modal{
      @include transition(0.5s);
      &.active{
            bottom: 0;
            @include transition(0.5s);
      }
 }
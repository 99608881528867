$gray-1100: #777E90;
$gray-1200: #E6E8EC;
$gray-1300: #F1F2F4;
$gray-1400: #FCFCFD;
$primary-color: #58BD7D;
$russian-black-1200: #23262F;
$blue-color: #3772FF;
$font24: 24px;
$font16: 16px;
$font14: 14px;
$font12: 12px;

@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
      font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
      font-family: "DM Sans", sans-serif;
      font-weight: $font-weight;
}

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}

@mixin box-shadow($value) {
      -webkit-box-shadow: $value;
      -moz-box-shadow: $value;
      box-shadow: $value;
}

ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}
a{
      text-decoration: none;
}

.alert-default {
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font14;
      height: 40px;
      background-color: $blue-color;
      border: 2px solid $blue-color;
      display: flex;
      align-items: center;
      @include border_radius (90px);
      justify-content: center;
      width: 100%;

      &:hover {
            background-color: transparent;
            color: $blue-color;
      }
}


.modal-backdrop {
      background-color: rgba($gray-1300, 0.8);
      opacity: 1 !important;
}

.modal-dialog {
      max-width: 448px;
      width: 100%;
      margin: 0 auto;
      .modal-content {
            border: none;
      }
     
}
 
.leverage-modal {
      padding: 30px 32px;
      max-width: 448px;
      width: 100%;
      margin: 0 auto;
      background-color: $gray-1400;
      @include border_radius(20px);
      border: none;
      @include box-shadow(0px 64px 64px -48px rgba(31, 47, 70, 0.12));

      .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 35px;

            h3 {
                  color: $russian-black-1200;
                  @include Poppins(600);
                  font-size: $font24;
                  line-height: 1;
            }

            a {
                  border: 2px solid $gray-1200;
                  @include border_radius (50%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
            }
      }
}


.leverage-box {
      h4 {
            color: $gray-1100;
            @include Poppins(600);
            font-size: $font12;
            text-align: center;
            margin-bottom: 22px;
      }

      p {
            color: $russian-black-1200;
            @include Poppins(600);
            font-size: $font12;
            line-height: 20px;
            text-align: center;
            margin: 20px 0 41px;
      }

      ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            li {
                  a {
                        color: $gray-1400;
                        @include DMSans(700);
                        font-size: $font14;
                        background-color: $primary-color;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 24px;
                        @include border_radius (90px);
                        padding: 0 16.5px;
                  }
            }
      }
}

.quantity-box {
      border: 2px solid $gray-1200;
      @include border_radius (12px);
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      button {
            border: none;
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            width: 24px;
            height: 24px;
            justify-content: center;
      }

      .count {
            border: none;
            @include border_radius(0);
            padding: 0;
            color: $russian-black-1200;
            text-align: center;
            @include Poppins(500);
            font-size: $font16;
            flex: 1;
            background-color: transparent;
      }
}
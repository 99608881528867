@media (max-width: 1600px) {}

@media (max-width: 1350px) {
      .bitcloud-sec .graph-holder {
            width: calc(100% - 510px);
      }

      .bitcloud-sec .bitcloud-right {
            max-width: 510px;
      }

      .bitcloud-order {
            max-width: 220px;
      }

      .bitcloud-order .order-price-bottom ul li {
            padding: 8px 9px;
      }

      .bit-order-left {
            max-width: calc(100% - 286px);
      }

      .bit-order-right {
            max-width: 286px;
      }

      .deposit-btn ul {
            gap: 10px;
      }

      .deposit-btn ul li a {
            height: 45px;
            gap: 5px;
            padding: 0 15px;
      }

      .bit-order-right .deposit-inner ul li {
            font-size: 14px;
      }

      .alert-main {
            flex-wrap: wrap;
            justify-content: flex-start;
      }
}

@media (max-width: 1199px) {
      header {
            padding: 20px 28px;
      }

      .navbar-nav .nav-item.dropdown .dropdown-menu {
            display: none !important;
      }

      .navbar-nav .nav-item.dropdown .dropdown-menu.show {
            display: block !important;
      }

      .exchange {
            padding: 18px 48px 18px 20px;
      }

      .exchange-title h3 {
            font-size: 16px;
      }

      .exchange-price h3 {
            font-size: 16px;
      }

      .exchange-right ul li {
            border: none;
      }

      .exchange-right ul li {
            border: none;
            margin: 0;
            padding-right: 32px;
      }

      .exchange-box p {
            margin-bottom: 8px;
      }

      .bitcloud-sec .graph-holder {
            width: calc(100% - 256px);
      }

      .bitcloud-sec .bitcloud-right {
            max-width: 256px;
      }

      .bitcloud-order {
            max-width: 100%;
      }

      .bitcloud-sec {
            padding-bottom: 27px;
      }

      .bit-order-left {
            margin-top: 0;
            max-width: calc(100% - 256px);
            padding-right: 37px;
      }

      .deposit-btn ul {
            gap: 20px;
      }

      .deposit-btn ul li a {
            height: 40px;
            font-size: 14px;
            gap: 12px;
            padding: 0 10px;
      }

      .bit-order-right .deposit-inner ul li {
            font-size: 12px;
      }

      .bit-order-right {
            padding: 20px 16px;
            max-width: 256px;
            width: 100%;
      }

      .futures-limit {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            min-height: auto;
      }

      .limit-media {
            display: grid;
            grid-template-columns: 6fr 6fr;
            gap: 40px;
      }

      .futures-limit .limit-content .media span {
            border-radius: 3.86px;
            height: 25px;
            width: 48px;
            font-size: 11.57px;
            background-color: #3772FF;
      }

      .limit-media-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
      }

      footer .footer-contect {
            padding-left: 15px;
      }

      footer .footer-left {
            gap: 80px;
      }

      footer .footer-newsletter {
            max-width: 100%;
      }

      footer .links-mobile {
            display: block;
      }

      .history-spot .bit-table .table-responsive table.w-100 {
            width: 950px !important;
      }
}

@media (max-width: 991px) {
      header {
            padding: 20px 0px;
      }

      .navbar .navbar-brand {
            padding: 4px 15px 4px 0px;
            margin: 0 15px 0 0;
      }

      .header-media .header-right .header-list {
            margin-left: 15px;
      }

      .header-media .header-right .header-list ol {
            gap: 18px;
      }

      .exchange {
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
      }

      .exchange-right {
            width: 100%;
      }

      .exchange-right ul {
            justify-content: space-between;
            margin: 30px 0 0;
      }

      .bit-order-left {
            padding-right: 16px;
      }

      .limit-media {
            gap: 20px;
      }

      .futures-limit .limit-content .limit-price ul li {
            font-size: 11px;
            gap: 4px;
      }

      .bit-table .table-responsive table thead tr th {
            font-size: 10px;
            padding-bottom: 15px;
      }

      .bit-table .table-responsive table tbody tr td {
            font-size: 10px;
      }

      footer .footer-contect {
            padding-left: 0;
      }

      footer .footer-left {
            gap: 50px;
      }

      .history-spot .media .date-range .form-group .form-control {
            width: 180px;
      }
}

@media (max-width: 767px) {
      .container {
            max-width: 100%;
      }

      header {
            padding: 48px 20px 24px;
      }

      .navbar .navbar-brand {
            padding: 0;
            margin: 0;
            border: none;
            position: relative;
            z-index: 100;
      }

      .header-media {
            flex-direction: row-reverse;
            position: relative;
            z-index: 100;
      }

      .navbar-toggler {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 0;

      }

      .navbar-toggler:focus {
            box-shadow: none;
      }

      .user-img {
            height: 32px;
      }

      .header-media .header-right .header-list {
            margin-left: 0;
      }

      .header-media .header-right .header-list ol {
            gap: 20px;
      }

      .header-list {
            margin-right: 20px;
      }

      .navbar-collapse {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            background: #fff;
            height: 100%;
            padding: 120px 30px;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            transition: all 0.5s ease-in-out;
      }

      .navbar-collapse.active {
            opacity: 1;
            transition: all 0.5s ease-in-out;
            visibility: visible;
      }

      .navbar-nav .nav-item .nav-link {
            font-size: 18px;
      }

      body.active {
            overflow: hidden;
      }

      .navbar-nav {
            gap: 30px;
      }

      .bit-future-sec {
            padding: 16px;
      }

      .exchange {
            border-radius: 4px;
      }

      .exchange-title h3 {
            font-size: 24px;
      }

      .exchange-price h3 {
            font-size: 24px;
      }

      .exchange-left {
            flex-direction: column;
            width: 100%;
            gap: 12px;
            align-items: flex-start;
      }

      .exchange-price {
            width: 100%;
      }

      .exchange-price h3 {
            font-size: 24px;
            justify-content: space-between;
      }

      .exchange-right ul {
            justify-content: space-between;
            margin: 30px 0 0;
            flex-wrap: wrap;
            row-gap: 24px;
      }

      .exchange-right ul li {
            width: 50%;
            padding: 0;
      }

      .exchange-right ul li:first-child {
            padding-right: 0;
      }

      .exchange-box p {
            margin-bottom: 14px;
      }

      .bit-table .table-responsive table thead tr th {
            font-size: 12px;
            padding: 8px 0;
            border: none;
            color: #777E90;
      }

      .bit-table .table-responsive table thead tr th img {
            margin-left: 5px;
      }

      .bit-table .table-responsive table tbody tr td {
            font-size: 12px;
            padding: 4px 0;
            color: #777E90;
      }

      .bitcloud-order .order-price-bottom ul li {
            padding: 8px 16px;
      }

      .bitcloud-order .order-book {
            padding: 16px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
      }

      .order-book ul {
            display: flex;
            align-items: center;
            gap: 12px;
      }

      .bitcloud-order .order-book ul li a {
            width: 32px;
            border-radius: 4px;
            height: 32px;
            background-color: transparent;
      }

      .bitcloud-order .order-book ul li a.active {
            background-color: #F1F2F4;
      }

      .bitcloud-order .order-book a.drop-list {
            background-color: $gray-1300;
            width: 32px;
            height: 32px;
            @include border_radius (4px);
      }

      .inquiry-inner h3 {
            font-size: 40px;
      }

      .inquiry-sec {
            padding: 34px 0 64px;
      }

      .inquiry-inner form {
            margin: 40px auto 0;
      }

      footer {
            margin-top: 0;
            padding: 64px 22px 0;
      }

      footer .footer-left {
            gap: 0;
            display: block;
            border-right: none;
            border-bottom: 1px solid #E6E8EC;
      }

      footer .footer-contect {
            border-right: none;
            border-bottom: 1px solid #E6E8EC;
            padding: 30px 0 38px;
      }

      footer .footer-newsletter {
            padding: 30px 0;
            max-width: 192px;
            margin: 0;
      }

      footer .footer-links h4 {
            cursor: pointer;
            display: flex;
      }

      .footer-links ul {
            margin-top: 30px;
      }

      .footer-logo {
            margin-bottom: 40px;
      }

      footer .footer-left {
            padding-top: 0;
            padding-bottom: 38px;
      }

      .copyright {
            border: none;
            padding: 0;
      }

      .copyright .copyright-inner {
            align-items: flex-start;
            flex-direction: column;
            border-top: 1px solid #E6E8EC;
            padding: 60px 0 48px;
      }

      .copyright .copyright-inner p {
            margin-bottom: 26px;
      }

      footer .links-mobile {
            display: none;
      }

      .footer-small-logo {
            display: none;
      }

      .footer-light-logo {
            display: block;
      }

      .dark-mode .footer-light-logo {
            display: none;
      }

      .dark-mode .footer-dark-logo {
            display: block;
      }

      .history-sec {
            padding: 50px 0;
      }

      .history-inner {
            padding: 20px;
      }

      .history-spot .media .date-range .form-group .form-control {
            width: 160px;
      }

      .trading-option-sec {
            padding: 0 16px 16px;
      }

      .bit-table-mobile {
            padding: 17px 16px 16px;
      }

      .trading-option-sec .image-holder {
            width: 100%;
      }

      .bit-table-mobile .bit-table .table-responsive table {
            width: 900px;
      }

      .option-media {
            grid-template-columns: 12fr;
            gap: 40px;
      }

      .bit-table-mobile .bit-table #pills-Orders2 .table-responsive table {
            width: 100%;
      }

      .alert-box {
            margin: 0 auto;
      }
}

@media (max-width: 575px) {
      .bit-table .table-responsive table {
            width: 456px;
      }

      .modal-dialog {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
            min-height: 100%;
            align-items: end;
      }

      .modal-body {
            padding: 26px;
      }

      .modal-body .title {
            margin-bottom: 26px;
      }

      .modal-body .title a {
            width: 33px;
            height: 33px;
      }

      .modal-body .title a svg {
            width: 11px;
            height: 11px;
      }

      .modal-body .title h3 {
            font-size: 20px;
            line-height: 1;
      }

      .modify-tp .media p {
            font-size: 10px;
      }

      .modify-tp .media span {
            font-size: 10px;
      }

      .modify-tp .media span img {
            height: 13px;
      }

      .modify-tp .media {
            margin-bottom: 18px;
      }

      .modify-box {
            padding: 16px 24px;
      }

      .modify-box ul li p {
            font-size: 10px;
      }

      .modify-box ul li span {
            font-size: 13px;
      }

      .modify-form form .form-group label {
            font-size: 10px;
            margin-bottom: 6px;
      }

      .modify-form form .form-group span {
            font-size: 11.69px;
      }

      .modify-form form .form-group .form-control {
            font-size: 11.69px;
            height: 40px;
            padding-right: 50px;
            padding-left: 13px;
      }

      .error-message {
            padding: 5px 7px;
      }

      .error-message p {
            font-size: 8px;
            line-height: 13px;
            margin-left: 6px;
            color: $yellow;
      }

      .error-message a {
            width: 13px;
            height: 13px;
      }

      .modify-form form .form-group .btn-default {
            margin-top: 26px;
            font-size: 13.36px;
            height: 40.71px;
      }

      .history-spot .media {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column-reverse;
      }

      .history-spot .media .date-range {
            gap: 10px;
            margin-bottom: 30px;
      }

      .history-spot .media .date-range .form-group .form-control {
            width: 100%;
      }

      .history-spot .bit-table .table-responsive table thead tr th {
            padding: 10px 10px;
            line-height: 15px;
      }

      .tabs-second {
            padding-bottom: 20px;
            margin-bottom: 20px;
      }

      .tabs-second .nav-pills {
            gap: 0;
      }

      .tabs-second .nav-pills .nav-item .nav-link {
            padding: 0 28px;
            height: 35px;
      }

      .history-spot .media .date-range.date-range-future,
      .history-spot .media .date-range.date-range-future .form-group {
            width: 100%;
      }

      .history-spot .media .date-range.date-range-future .form-group .form-control {
            width: 100%;
      }

      .swap-sec {
            padding: 0;
      }

      .swap-box {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
            padding: 20px;
            background-color: #F8F8F9;
      }

      .swap-left {
            max-width: 99px;
            width: 100%;
      }

      .btn-box img {
            height: 16px;
      }

      .arrow-box {
            right: 10px;
      }

      .swap-left .form-control {
            padding-left: 30px;
            padding-right: 20px;
      }

      .swap-media {
            gap: 10px;
      }

      .swap-right .form-control {
            font-size: 10px;
      }

      .alert-box {
            padding: 20px;
      }

      .option-table {
            padding: 24px 16px;
      }

      .option-media {
            gap: 20px;
      }

      .option-table .limit-btns ul {
            gap: 12px;
      }

      .alert-box {
            max-width: 100%;
      }
      .alert-box h3 {
            font-size: 34px;
         }
         .purchased-box p {
            font-size: 14px;
            line-height: 22px;
        }
}

@media (max-width: 374px) {
      header {
            padding: 48px 0px 24px;
      }

      .leverage-box ul li a {
            font-size: 12px;
            height: 20px;
            padding: 0 12px;
      }

      .leverage-box p {
            margin: 20px 0;
      }

      .history-inner {
            padding: 16px;
      }

      .tabs-second .nav-pills .nav-item .nav-link {
            padding: 0 20px;
      }
}
@import ../../../styles/helpers

.actions
    padding: 16px
    background: $n8
    border-radius: 4px
    +t
        position: fixed
        left: 0
        right: 0
        bottom: 0
        z-index: 5
        margin: 0
        box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1)
    +dark
        background: #17181B

.head
    display: flex
    align-items: center
    margin-bottom: 24px
    +t
        margin-bottom: 16px

.info
    +caption-2
    font-weight: 500
    color: $n4
    +m
        display: none
    a
        margin-left: 8px
        color: $n2
        transition: color .2s
        svg
            transition: fill .2s
        +dark
            color: $n6
            svg
                fill: $n6
        &:hover
            color: $p1
            svg
                fill: $p1

.nav
    display: flex
    margin-right: auto

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 16px

.wrapper
    +t
        position: fixed
        top: 100%
        left: 0
        right: 0
        z-index: 6
        background: $n8
        padding: 16px
        transition: all .3s
        +dark
            background: $n1
        &.show
            transform: translateY(-100%)
            box-shadow: 0px -16px 64px rgba(15, 15, 15, 0.1)

.btns
    display: none
    +t
        display: flex
        margin: 0 -6px
    .button
        flex: 0 0 calc(100% - 12px)
        width: calc(100% - 12px)
        margin: 0 6px

.typelink
    padding: 6px 12px
    +button-2
    transition: all .2s
    color: $n2
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 14px
    +dark
        color: $n8
    &:hover
        color: $n3
        +dark
            color: $n6
    @media (max-width: 768px)  // Example for smaller screens
        height: 24px // Adjust height

.typelink_wrapper

    @media (max-width: 768px)  // Example for smaller screens
        height: 24px

.trade_but

    @media (max-width: 768px)  // Example for smaller screens
        height: 30px

$gray-1100: #777E90;
$white: #ffffff;
$gray-1200: #E6E8EC;
$russian-black: #141416;
$russian-black-1200: #23262F;  
$primary-color: #58BD7D;
$secondary-color: #FF6838;
$blue-color: #3772FF;
$gray-1400: #FCFCFD;
$font14: 14px; 
$font13: 13.57px; 
$font12: 12px;
$font9: 9.231px;


@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
      font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
      font-family: "DM Sans", sans-serif;
      font-weight: $font-weight;
}

@mixin border_radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
      border-radius: $radius;
}

ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}

a {
      text-decoration: none;
}




.nav-pills {
      gap: 8px;
      margin-bottom: 22px;

      .nav-item {
            .nav-link {
                  border: none;
                  padding: 0 13px;
                  color: $gray-1100;
                  font-size: $font14;
                  @include DMSans(700);
                  height: 28px;
                  @include border_radius(100px);

                  &.active {
                        background-color: $gray-1200;
                        color: $russian-black-1200;
                  }
            }
      }
}

.limit-media-left {
      .nav-pills {
            border: 1px solid $gray-1100;
            @include border_radius (100px);
            max-width: 268px;
            width: 100%;
            margin: 0 auto 16px;
            gap: 0;

            .nav-item {
                  width: 50%;

                  .nav-link {
                        width: 100%;
                        color: $gray-1100;
                        font-size: $font13;
                        height: 42px;

                        &.active {
                              background-color: $primary-color;
                              color: $white;
                        }
                  }
            }
      }
}

.bit-table {
      .table-responsive {
            table {
                  width: 100%;
                  border-collapse: separate;
                  border-spacing: 0px 12px;

                  thead {
                        tr {
                              th {
                                    color: $gray-1100;
                                    @include Poppins(600);
                                    font-size: $font12;
                                    line-height: 1;
                                    text-align: center;
                                    border-bottom: 1px solid $gray-1200;
                                    padding-bottom: 20px;
                                    padding-top: 10px;
                                    vertical-align: middle;
                              }
                        }
                  }

                  tbody {
                        tr {
                              td {
                                    color: $russian-black;
                                    @include Poppins(500);
                                    font-size: $font12;
                                    line-height: 1;
                                    text-align: center;
                                    vertical-align: middle;

                                    span {
                                          display: flex;
                                          align-items: center;
                                          gap: 6px;
                                    }

                                    a {
                                          background-color: $secondary-color;
                                          @include border_radius(3.08px);
                                          height: 20.77px;
                                          display: inline-flex;
                                          padding: 0 7px;
                                          align-items: center;
                                          justify-content: center;
                                          color: $gray-1400;
                                          @include Poppins(700);
                                          font-size: $font9;
                                          margin: 0 auto;
                                          text-transform: uppercase;
                                    }

                                    &.green-color {
                                          color: $primary-color;
                                          @include Poppins(600);
                                    }
                              }
                        }
                  }
            }
      }

      .progress {
            height: 4px;
            max-width: 128px;
            width: 100%;
            margin: 0 auto;
            background-color: $gray-1200;
            @include border_radius (400px);
            margin-top: 28px;

            .progress-bar {
                  background-color: $blue-color;
                  @include border_radius (400px);
            }
      }
}
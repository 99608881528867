 .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 14px 0 0 0;
       ul {
             display: flex;
             align-items: center;
             justify-content: center;
             gap: 10px;

             li {
                   a {
                         width: 30px;
                         height: 30px;
                         @include border_radius(50%);
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         color: $gray-1100;
                         @include DMSans(700);
                         font-size: $font10;

                         &.pagination-link {
                               &:hover {
                                     background-color: $blue-color;
                                     color: $gray-1200;
                               }

                               &.active {
                                     background-color: $blue-color;
                                     color: $gray-1200;
                               }
                         }
                   }
             }
       }
 }
@import ../../styles/helpers

.wrapper
    padding: 32px
    gap: 32px
    border-radius: 20px
    background: #FCFCFD
    display: flex
    flex-direction: column
    align-items: center
    +dark
        background: #141416
    @media (max-width: 768px)
        padding: 16px

.countdown_wrapper
    display: flex
    justify-content: center
    span
        font-size: 32px
        margin: 10px
        @media (max-width: 768px)
            margin: 0px
            font-size: 20px
    div
        margin: 0px 10px    
        span
            display: block
            text-align: center
            font-size: 16px
        span:nth-child(1)
            font-size: 32px
            color: #434c67
            +dark
                color: #FCFCFD
            @media (max-width: 768px)
                font-size: 20px

.countdown_info_wrp
    background: #F4F5F6
    border-radius: 4px
    padding: 20px
    display: flex
    gap: 4px
    +dark
        background: #18191D
    @media (max-width: 768px)
        padding: 10px

 
$gray-1400: #FCFCFD;
$gray-1100: #777E90;  
$russian-black: #141416;
$russian-black-1200: #23262F; 
$gray-1200: #E6E8EC;  
$primary-color: #58BD7D; 
$secondary-color: #FF6838;
$gray-1500: #353945;
$blue-color: #3772FF; 
$gray-1600: #F4F5F6; 
$gray-1700: #B1B5C3;
$font14: 14px; 
$font10: 10px; 
$font12: 12px; 
$font16: 16px; 

@mixin Poppins($font-weight: normal) {
     font-family: 'Poppins', sans-serif;
     font-weight: $font-weight;
 }
 
 @mixin DMSans($font-weight: normal) {
     font-family: "DM Sans", sans-serif;
     font-weight: $font-weight;
 }
  
 @mixin border_radius($radius) {
     -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
     -ms-border-radius: $radius;
     border-radius: $radius;
 }
 @mixin transition($time) {
      -webkit-transition: all $time ease-out 0s;
      -moz-transition: all $time ease-out 0s;
      -ms-transition: all $time ease-out 0s;
      -o-transition: all $time ease-out 0s;
      transition: all $time ease-out 0s;
  }

 ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}
a{
      text-decoration: none;
}

.btn-default {
      @include border_radius (90px);
      height: 48px;
      width: 100%;
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font16;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(0.5s);
      border: 2px solid transparent;
      &:hover{
           background-color: transparent;
           color: $blue-color;
           @include transition(0.5s);
     }
}

.green-bg {
      background-color: $primary-color;
      @include transition(0.5s);
      &:hover{
           background-color: transparent;
           border-color: $primary-color;
           color: $primary-color;
           @include transition(0.5s);
     }
}
.orange-bg{
     background-color: $secondary-color;
     &:hover{
           background-color: transparent;
           border-color: $secondary-color;
           color: $secondary-color;
           @include transition(0.5s);
     }
}


 
 /***** OPTION TABLE *******/
 .option-table {
      background-color: $gray-1400;
      @include border_radius(4px);
      padding: 24px 35px;

      .limit-btns {
            margin-top: 22px;

            ul {
                  gap: 56px;
            }
      }
}

.option-media {
      display: grid;
      grid-template-columns: 6fr 6fr;
      gap: 56px;

      .option-table-left {
            &.futures-limit {
                  min-height: auto;
                  padding: 0;
                  @include border_radius(0);
                  background-color: transparent;
            }

            form {
                  .form-group {
                        margin-bottom: 12px !important;
                  }
            }

            .bid-slider {
                  margin-bottom: 24px;
            }
      }

       
}
.balance-media {
     display: flex;
     align-items: center;
     margin-bottom: 22px;
     justify-content: space-between;

     h4 {
           color: $gray-1100;
           font-size: $font14;
           line-height: 1;
           @include Poppins(500);
     }

     span {
           color: $russian-black;
           font-size: $font14;
           line-height: 1;
           @include Poppins(500);
           display: block;
           line-height: 1;
     }
}
.option-box {
      background-color: $gray-1600;
      @include border_radius(4px);
      text-align: center;
      padding: 21px 20px;
      margin-bottom: 16px;

      &:last-child {
            margin: 0;
      }

      p {
            color: $gray-1100;
            font-size: $font12;
            line-height: 1;
            @include Poppins(500);
            margin-bottom: 12px;
      }

      h4 {
            color: $russian-black;
            font-size: $font16;
            line-height: 1;
      }
}

.limit-btns {
      ul {
            gap: 12px;
            width: 100%;
            display: grid;
            grid-template-columns: 6fr 6fr;
      }
}
.d-md-none {
      display: none !important;
  }

header {
      background-color: $gray-1400;
      padding: 20px 8px;
}

.navbar {
      padding: 0;

      .navbar-brand {
            padding: 4px 33px 4px 0px;
            margin: 0 33px 0 0;
            border-right: 1px solid $gray-1200;
            display: flex;
            align-items: center;
      }
}

.navbar-nav {
      gap: 44px;

      .nav-item {
            .nav-link {
                  padding: 0;
                  color: $gray-1100;
                  @include DMSans(700);
                  line-height: normal;
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  font-size: $font14;

                  &:hover {
                        color: $russian-black-1200;
                        svg{
                              path{
                                    fill:  $russian-black-1200;
                              }
                        }
                  }
            }
      }
}

.header-media {
      order: 2;
      display: flex;
      align-items: center;

      .header-right {
            display: flex;
            align-items: center;

            .header-list {
                  margin-left: 46px;

                  ol {
                        display: flex;
                        align-items: center;
                        list-style: none;
                        gap: 24px;

                        li {
                              a {
                                    display: flex;
                                    align-items: center;
                              }
                        }
                  }
            }
      }
}

.currency-btn {
      .currency-dropdown {
            color: $russian-black-1200;
            @include DMSans(700);
            line-height: 1;
            display: flex;
            align-items: center;
            gap: 18px;
            font-size: $font14;
      }
}

.lightning-btn {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
            svg {
                  path {
                        fill: $russian-black-1200;
                  }
            }
      }
}

.notification-btn {
      position: relative;
      width: 40px;
      height: 40px;
      justify-content: center;

      &:hover {
            svg {
                  path {
                        fill: $russian-black-1200;
                  }
            }
      }
}

.staus {
      @include border_radius (50%);
      width: 12px;
      height: 12px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
}

.wallet-btn {
      border: 2px solid $gray-1200;
      height: 40px;
      @include border_radius (90px);
      display: flex;
      align-items: center;
      justify-content: center;
      @include DMSans(700);
      color: $russian-black-1200;
      font-size: $font14;
      padding: 0 14px;

      &:hover {
            background-color: $russian-black-1200;
            color: $white;
            border-color: $russian-black-1200;
      }
}

.mood-btn {
      &:hover {
            svg {
                  path {
                        fill: $russian-black-1200;
                  }
            }
      }
}
 $primary-color: #58BD7D;
 $russian-black: #141416;
 $russian-black-1200: #23262F;
 $yellow: #FFD166;
 $red: #FF3B30;
 $blue-color: #3772FF;
 $gray-1100: #777E90;
 $gray-1200: #E6E8EC;
 $gray-1400: #FCFCFD;
 $gray-1600: #F4F5F6;
 $gray-1900: #1F2F46;
 $font48: 48px;
 $font24: 24px;
 $font16: 16px;
 $font14: 14px;
 $font12: 12px;


 @mixin border_radius($radius) {
     -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
       -ms-border-radius: $radius;
       border-radius: $radius;
 }

 @mixin box-shadow($value) {
      -webkit-box-shadow: $value;
       -moz-box-shadow: $value;
       box-shadow: $value;
 }


@mixin Poppins($font-weight: normal) {
      font-family: 'Poppins', sans-serif;
       font-weight: $font-weight;
 }

 @mixin DMSans($font-weight: normal) {
       font-family: "DM Sans", sans-serif;
     font-weight: $font-weight;
}
ul {
      list-style: none;
      color: #000;
      margin: 0;
      padding: 0;

      li {
            list-style: none;
      }
}
a{
      text-decoration: none;
}
.alert-default{
      color: $gray-1400;
      @include DMSans(700);
      font-size: $font14;
      height: 40px;
      background-color: $blue-color;
      border: 2px solid $blue-color;
      display: flex;
      align-items: center;
      @include border_radius (90px);
      justify-content: center; 
      width: 100%;
      &:hover{
            background-color: transparent;
            color: $blue-color;
      }
 }
 .cancel-btn{
      color: $russian-black-1200;
      @include DMSans(700);
      font-size: $font14;
      height: 40px;
      border: 2px solid $gray-1200;
      background-color: transparent;
      display: flex;
      align-items: center;
      @include border_radius (90px);
      justify-content: center; 
      width: 100%;
      &:hover{
            background-color: $gray-1200; 
      }
 }


.alert-popup {
      padding: 0 0 50px 0;
}

.alert-main {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      gap: 15px;
}

.alert-box {
      max-width: 334px;
      width: 100%;
      background-color: $gray-1400;
      @include border_radius(20px);
      @include box-shadow (0px 64px 64px -48px rgba($gray-1900, 0.12));
      text-align: center;
      padding: 36px 32px 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
            font-size: $font16;
            @include Poppins(500);
            line-height: 1;
            margin-bottom: 30px;
      }

      h3 {
            color: $russian-black-1200;
            font-size: $font48;
            @include DMSans(700);
            line-height: 1;
            letter-spacing: -0.96px;
            margin-bottom: 30px;
      }

      p {
            color: $russian-black-1200;
            font-size: $font12;
            line-height: 1;
            @include Poppins(500);
            margin: 0 0 28px;
      }

      .alert-btn {
            ul {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;
                  list-style: none;
                  padding: 0;

                  li {
                        width: 50%;
                  }
            }
      }
}

.red-clr {
      color: $red;
}

.yellow-clr {
      color: $yellow;
}

.green-clr {
      color: $primary-color;
}

.purchased-box {
      max-width: 448px;
      width: 100%;

      h4 {
            font-size: $font24;
            margin-bottom: 36px;
      }

      p {
            font-size: $font16;
            line-height: 24px;
      }

      .alert-default {
            font-size: $font16;
            height: 48px;
      }

      .cancel-btn {
            font-size: $font16;
            height: 48px;
      }
}
 
 
.blue-clr {
      color: $blue-color  !important;
}
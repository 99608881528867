 .bit-future-sec {
       padding: 4px;
 }

 /****** EXCHANGE SEC ******/
 .exchange {
       background-color: $gray-1400;
       display: flex;
       align-items: center;
       padding: 12.5px 50px 12.5px 20px;
       justify-content: space-between;
 }

 .exchange-left {
       display: flex;
       align-items: center;
       gap: 32px;
 }

 .exchange-title {
       h3 {
             display: flex;
             align-items: center;
             color: $russian-black;
             @include Poppins(600);
             font-size: $font24;
             line-height: 1;
             gap: 4px;

             span {
                   background-color: $primary-color;
                   @include border_radius(24px);
                   width: 35px;
                   height: 24px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   color: $gray-1400;
                   font-size: $font12;
             }
       }

       p {
             color: $gray-1100;
             @include Poppins(600);
             font-size: $font12;
             line-height: 1;
             margin-top: 13px;
       }
 }

 .exchange-price {
       h3 {
             display: flex;
             align-items: center;
             color: $secondary-color;
             @include Poppins(600);
             font-size: $font24;
             line-height: 1;
             gap: 14px;
             margin-bottom: 3px;

             a {
                   width: 32px;
                   height: 32px;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   @include border_radius (32px);
                   border: 2px solid $gray-1200;
             }
       }

       p {
             display: flex;
             align-items: center;
             color: $gray-1100;
             @include Poppins(600);
             font-size: $font12;
             line-height: 1;
             gap: 5px;
       }
 }

 .exchange-right {
       ul {
             display: flex;
             align-items: center;

             li {
                   border-right: 1px solid $gray-1200;
                   margin-right: 24px;
                   padding-right: 62px;

                   &:first-child {
                         padding-right: 32px;
                   }

                   &:last-child {
                         border-right: none;
                         margin: 0;
                         padding-right: 0;
                   }
             }
       }
 }

 .exchange-box {
       p {
             display: flex;
             align-items: center;
             color: $gray-1100;
             @include Poppins(400);
             font-size: $font12;
             line-height: 1;
             margin-bottom: 12px;

             span {
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   width: 16px;
                   margin-right: 4px;
             }
       }

       h4 {
             color: $russian-black;
             @include Poppins(500);
             font-size: $font14;
             line-height: 1;

             &.green-color {
                   color: $primary-color;
             }
       }
 }

 /****** BITCLOUD SEC ******/
 .bitcloud-sec {
       display: flex;
       align-items: flex-start;
       padding: 0 4px 4px;
       gap: 4px;

       .graph-holder {
             display: flex;
             align-items: center;
             width: calc(100% - 621px);
             height: 502px;

             img {
                   width: 100%;
                   width: 100%;
                   height: 100%;
                   object-fit: cover;
                   object-position: center;
                   @include border_radius(4px);
             }
       }

       .bitcloud-right {
             max-width: 621px;
             width: 100%;
             display: flex;
             align-items: flex-start;
             gap: 4px;
       }
 }

 .bitcloud-order {
       @include border_radius(4px);
       background-color: $gray-1400;
       padding-bottom: 8px;
       max-width: 256px;
       width: 100%;

       .order-book {
             padding: 16px 8px;

             a {
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   color: $russian-black-1200;
                   @include DMSans(700);
                   line-height: 1;
                   font-size: $font11;
                   background-color: $gray-1200;
                   border-radius: 86px;
                   height: 24.07px;
                   width: 85.15px;
             }
       }

       .order-price-top {
             margin-bottom: 4px;

             ul {
                   display: grid;
                   grid-template-columns: 4fr 4fr 4fr;

                   li {
                         @include Poppins(600);
                         font-size: $font12;
                         line-height: 20px;
                         color: $gray-1100;
                         padding: 4px 15px;

                         span {
                               display: block;
                               text-align: right;
                         }

                         &:last-child {
                               text-align: right;
                         }
                   }
             }
       }

       .order-price-bottom {
             ul {
                   display: grid;
                   grid-template-columns: 4fr 4fr 4fr;
                   position: relative;
                   margin-bottom: 4px;

                   &:last-child {
                         margin: 0;
                   }

                   li {
                         color: $gray-1500;
                         @include Poppins(500);
                         font-size: $font12;
                         line-height: 1;
                         text-align: left;
                         padding: 8px 15px;

                         span {
                               display: block;
                               color: $secondary-color;
                               @include Poppins(600);
                         }

                         &.price-progress {
                               background-color: rgba($secondary-color, 0.15);
                               width: 100%;
                               height: 100%;
                               position: absolute;
                               top: 0;
                               right: 0;
                               padding: 0;
                         }
                   }
             }

             &.order-price-increase {
                   ul {
                         li {
                               span {
                                     color: $primary-color;
                               }

                               &.price-progress {
                                     background-color: rgba($primary-color, 0.15);
                               }
                         }
                   }
             }
       }
 }

 .price-increase {
       padding: 13px 15px;
       margin: 13px 0px;

       ul {
             display: flex;
             align-items: center;
             gap: 13px;

             li {
                   color: $russian-black-1200;
                   @include Poppins(500);
                   font-size: $font16;
                   display: flex;
                   align-items: center;

                   &.green-color {
                         color: $primary-color;
                   }
             }
       }
 }

 

 /****** BIT ORDER *****/
 .bit-order-sec {
       padding: 0 4px;
       display: flex;
       gap: 4px;
 }

 .bit-order-left {
       background-color: $white;
       @include border_radius(4px);
       padding: 16px;
       width: 100%;
       max-width: calc(100% - 361px);
       margin-top: -39px;
 }
 
 .bit-order-right {
       background-color: $white;
       @include border_radius(4px);
       padding: 40px 16px;
       max-width: 361px;
       width: 100%;

       .deposit-inner {
             margin: 28px 0;

             ul {
                   li {
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         color: $gray-1100;
                         @include Poppins(500);
                         font-size: $font16;
                         line-height: 1;
                         border-bottom: 1px solid $gray-1200;
                         padding: 16px 0;

                         span {
                               color: $russian-black-1200;
                               display: inline-block;
                         }

                         &:last-child {
                               border-bottom: none;
                         }
                   }
             }
       }
 }

 .deposit-btn {
       ul {
             display: flex;
             align-items: center;
             justify-content: center;
             gap: 20px;

             li {
                   a {
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         border: 2px solid $gray-1200;
                         @include border_radius(90px);
                         height: 48px;
                         color: $russian-black-1200;
                         @include DMSans(700);
                         font-size: $font16;
                         gap: 12px;
                         padding: 0 18px;

                         &.active {
                               background-color: $blue-color;
                               border-color: $blue-color;
                               color: $white;

                               svg {
                                     path {
                                           fill: $white;
                                     }
                               }
                         }

                         &:hover {
                               background-color: $blue-color;
                               border-color: $blue-color;
                               color: $white;

                               svg {
                                     path {
                                           fill: $white;
                                     }
                               }
                         }
                   }
             }
       }
 }

 .bit-table-mobile {
       padding: 17px 4px 4px;

       .bit-table {
             background-color: $gray-1400;
             @include border_radius(4px);
             padding: 20px 16px;
       }
 }

 /****** BITCLOUD MOBILE ******/
 .bitcloud-mobile {
       padding: 0 16px 110px;

       .nav-pills {
             justify-content: space-between;
             margin-bottom: 16px;
             gap: 0;

             .nav-item {
                   .nav-link {
                         color: $gray-1500;

                         &.active {
                               background-color: $gray-1500;
                               color: $white;
                         }
                   }
             }
       }

       .graph-holder {
             img {
                   @include border_radius (4px);
             }
       }
 }

 .markrt-trade {
       background-color: $gray-1400;
       @include border_radius (4px);
       padding: 16px;

       .form-control {
             background-color: $gray-1400;
             border: 1px solid $gray-1200;
             @include border_radius (12px);
             height: 40px;
             padding-left: 12px;
             color: $russian-black-1200;
             @include Poppins(700);
             font-size: $font12;
       }
 }

 .limit-btns-mobile {
       background-color: $gray-1400;
       position: fixed;
       left: 0;
       bottom: 0;
       padding: 16px 16px 32px 16px;
       width: 100%;
 }

 .order-book-right {
       display: flex;
       align-items: center;
       gap: 12px;

       .relative {
             position: relative;

             .form-control {
                   @include border_radius (4px);
                   background-color: $gray-1300;
                   height: 32px;
                   color: $russian-black-1200;
                   @include Poppins(600);
                   font-size: $font12;
                   border: none;
                   width: 63px;
             }

             span {
                   position: absolute;
                   top: 50%;
                   @include translate (0, -50%);
                   right: 16px;
                   display: flex;
                   align-items: center;
             }
       }

 }

 .currencies-pair {
       background-color: $gray-1400;
       @include border_radius (4px);
       padding: 16px;

       ul {
             display: flex;
             align-items: center;
             gap: 8px;

             li {
                   a {
                         color: $gray-1100;
                         @include DMSans(700);
                         font-size: $font14;
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         @include border_radius (100px);
                         height: 28px;
                         padding: 0 13px;

                         &.active {
                               background-color: $gray-1200;
                               color: $russian-black-1200;
                         }
                   }
             }
       }

       form {
             position: relative;
             margin-top: 12px;

             .form-control {
                   border: 2px solid $gray-1200;
                   @include border_radius (8px);
                   height: 40px;
                   width: 100%;
                   padding-left: 16px;
                   color: $gray-1100;
                   @include Poppins(400);
                   font-size: $font14;
                   box-shadow: none;
                   background-color: transparent;
                   padding-right: 45px;
             }

             a {
                   position: absolute;
                   top: 50%;
                   right: 14px;
                   @include translate (0, -50%);
             }
       }

       table {
             width: 100%;
             border-collapse: separate;
             border-spacing: 0px 12px;

             thead {
                   tr {
                         th {
                               color: $gray-1100;
                               @include Poppins(600);
                               font-size: $font12;
                               padding: 2px 0;

                               &:last-child {
                                     text-align: right;
                               }
                         }
                   }
             }

             tbody {
                   tr {
                         td {
                               color: $russian-black-1200;
                               @include Poppins(600);
                               font-size: $font12;
                               vertical-align: middle;
                               padding: 2px 0;

                               &:last-child {
                                     text-align: right;
                               }

                               &.red-color {
                                     color: $secondary-color;
                               }

                               &.green-color {
                                     color: $primary-color;
                               }

                               p {
                                     display: flex;
                                     align-items: center;

                                     img {
                                           margin-right: 5px;
                                     }

                                     span {
                                           color: $gray-1100;
                                     }
                               }
                         }
                   }
             }
       }
 }

 .spot-buy-modal {
       position: fixed;
       left: 50%;
       top: 50%;
       z-index: 100;
       @include translate (-50%, -50%);
       width: 100%;
       height: 100%; 
       align-items: center;
       justify-content: center;
       background-color: rgba($black, 0.5);
       display: none;
       &.active{
            display: flex;
       }

       .futures-limit {
             min-height: auto;
             max-width: 361px;
             padding: 26px 16px;
       }

       .bid-slider {
             margin-bottom: 24px;
             padding: 0 20px;
       }

       .limit-price {
             ul {
                   li {
                         width: 100%;
                         justify-content: space-between;
                   }
             }
       }
       .limit-media-left {
            .nav-pills {
                  .nav-item {
                        &:last-child {
                              .nav-link {
                                    &.active {
                                          background-color: $secondary-color;
                                    }
                              }
                        }
                  }
            }
      }
 }


 .swap-sec {
      padding: 40px 0;
}

 
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@mixin Poppins($font-weight: normal) {
    font-family: 'Poppins', sans-serif;
    font-weight: $font-weight;
}

@mixin DMSans($font-weight: normal) {
    font-family: "DM Sans", sans-serif;
    font-weight: $font-weight;
}
 
@mixin border_radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}


@mixin transition($time) {
    -webkit-transition: all $time ease-out 0s;
    -moz-transition: all $time ease-out 0s;
    -ms-transition: all $time ease-out 0s;
    -o-transition: all $time ease-out 0s;
    transition: all $time ease-out 0s;
}


@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}

@mixin user-select($value) {
    -webkit-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}


@mixin box-sizing($value) {
    -webkit-box-sizing: $value;
    -moz-box-sizing: $value;
    box-sizing: $value;
}


@mixin animation($value) {
    -webkit-animation: $value;
    -moz-animation: $value;
    -o-animation: $value;
    animation: $value;
}


@mixin animation-delay($value) {
    -webkit-animation-delay: $value;
    -moz-animation-delay: $value;
    -o-animation-delay: $value;
    animation-delay: $value;
}



@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}


// Placeholder Mixins
@mixin placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}



@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}
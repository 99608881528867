/////////////////////////////////
//    Theme Primary Colors
//////////////////////////////////;
$dark: #18191D;
$body: #f2f2f2;
$white: #ffffff;
$black: #000; 
$black-1200: #0F0F0F; 
$primary-color: #58BD7D; 
$secondary-color: #FF6838; 
$blue-color: #3772FF; 
$russian-black: #141416; 
$russian-black-1200: #23262F;  
$gray-1100: #777E90; 
$gray-1200: #E6E8EC;  
$gray-1300: #F1F2F4;  
$gray-1400: #FCFCFD;  
$gray-1500: #353945;  
$gray-1600: #F4F5F6;  
$gray-1700: #B1B5C3;  
$gray-1800: #F8F8F9;  
$gray-1900: #1F2F46;  
$yellow: #FFD166;  
$red: #FF3B30;  
// COLOR OPACITY
/////////////////////////////////
//        FONT SIZES
//////////////////////////////////
$font64: 64px; 
$font48: 48px; 
$font24: 24px; 
$font16: 16px; 
$font14: 14px; 
$font13: 13.57px; 
$font12: 12px; 
$font11: 11.75px; 
$font10: 10px; 
$font9: 9.231px; 
